.tos__dashboard {
    min-height: 100%;
    max-width: 80rem;
    margin: 24px auto;
    border-width: 2px;
    box-shadow: 0 6px 14px 0 rgba(0,0,0,0.1);
    padding: 22px 2rem;

    h4 {
        line-height: 28px;
        font-size: 20px;
        color: $color-term-text;
    }

    h3 {
        line-height: 38px;
        font-size: 32px;
        color: $color-term-text;
    }

    h5 {
        line-height: 38px;
        font-size: 16px;
        color: $color-term-text;
    }

    p{
        font-size: 14px;
        color: $color-term-text;
    }

    img{
        height: 48px;
        width: 48px;
    }

    li{
        list-style: disc;
    }
}

.tos__dashboard-title{
    display: flex;
    justify-content: center;
    font: 400;
    font-family: 'Courier New', Courier, monospace;
}

.tos__dashboard-title-1{
    padding: 18px 0px;
    display: flex;
    justify-content: center;
    font: 400;
    font-family: 'Courier New', Courier, monospace;
}

.tos__dashboard-content {
    // display: flex;
    text-align: justify;
    font: 400;
    font-family: 'Courier New', Courier, monospace;
}

.tos__dashboard-content-list {
    margin: 16px 0px;
}

.tos__dashboard-header-image {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

// .tos__dashboard-header-image {
//     display: flex;
//     justify-content: flex-start;
// }

.tos__dashboard-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
}

.tos__dashboard-content-1{
    // display: flex;
    border-top-width: 8px;
    border-color: rgb(44, 43, 43);
    padding: 14px;
    border: solid;
    text-align: justify;
    font: 400;
    font-family: 'Courier New', Courier, monospace;
}