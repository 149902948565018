.filter__list-wrap {
    position: absolute;
    z-index: 101;
    width: 20%;
    right: 0;
    min-width: 120px;
    top: 126px;
}

.filter__list-wrap-company {
    position: absolute;
    z-index: 101;
    width: 20%;
    right: 150px;
    min-width: 120px;
    top: 126px;
}

.filter__menu {
    width: 86%;
    border-radius: 10%;
    border: none;
    padding: 15px 10px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;
  
    @include themify($themes) {
      background: themed('colorBackground');
    }
  
    button {
      padding: 0;
  
      &:hover {
        @include themify($themes) {
          background-color: themed('colorHover');
        }
      }
    }
  
    *:focus {
      outline: none;
    }
  }