.card {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  border: none;
  background-color: transparent;

  &.card--not-full-height {
    height: auto;
  }
}

.card-body {
  padding: 30px;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.card-affiliate-Money {
  width: 80px;
  height: 80px;
  position: relative;
  box-shadow: 0 10px 30px 1px #4a00e0;
  //  #4a00e0
  // background: rgba(255, 117, 62, 0.05);
  border-radius: 10px;

  @include themify($themes) {
    color: #8e2de2;
    background: #fff;
  }
}

.card-affiliate-payout {
  width: 80px;
  height: 80px;
  position: relative;
  box-shadow: 0 10px 30px 1px #80D0C7;
  //  #4a00e0
  // background: rgba(255, 117, 62, 0.05);
  border-radius: 10px;

  @include themify($themes) {
    color: #0093E9;
    background: #fff;
  }
}

.card-affiliate-compare {
  width: 80px;
  height: 80px;
  position: relative;
  box-shadow: 0 10px 30px 1px #F76B1C;
  //  #4a00e0
  // background: rgba(255, 117, 62, 0.05);
  border-radius: 10px;

  @include themify($themes) {
    color: #8e2de2;
    background: #fff;
  }
}

.card-money {
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @include themify($themes) {
    color: #8e2de2;
  }
}

.card-rocket {
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @include themify($themes) {
    color: #0093E9;
  }
}

.card-compare {
  font-size: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @include themify($themes) {
    color: #F76B1C;
  }
}

.card-dollar {
  font-size: 40px;
  position: absolute;
  right: 10%;
  top: 40%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);

  @include themify($themes) {
    color: #fff;
  }
}

.card-panel-amount {
  font-size: 40px;
  display: flex;
  align-items: center;

  @include themify($themes) {
    color: #fff !important;
  }
}

.card-panel-amount-text {
  @include themify($themes) {
    color: #fff !important;
  }
}

.card__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.card__title-email {
  margin-bottom: 40px;
  text-transform: lowercase;
  font-size: 16px;
  // margin-left: 40px;
  font-weight: 300;
  position: relative;

  &:not(:first-child) {
    margin-top: 40px;
  }

  .subhead {
    text-transform: none;
    font-size: 12px;
    line-height: 18px;
    opacity: 0.7;
    margin-top: 3px;
  }

  * {
    margin-bottom: 0;
  }

  h5 {
    font-size: 13px;
  }
}

.squared-corner-theme {

  .card-body {
    border-radius: 0;
  }
}

.blocks-with-shadow-theme {

  .card-body {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }

  .card-body-commision {
    box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  }
}

.color__pdf {
  @include themify($themes) {
    color: themed('colorTextProfile');
  }
}

.card-body-commision {
  padding: 30px;
  height: 100%;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  @include themify($themes) {
    background: linear-gradient(to right, #8e2de2, #4a00e0) !important;
  }
}

.card-body-payout {
  padding: 30px;
  height: 100%;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  @include themify($themes) {
    background: linear-gradient(to right, #0093E9, #80D0C7) !important;
  }
}

.card-body-compare {
  padding: 30px;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);

  @include themify($themes) {
    background: linear-gradient(to right, #FAD961, #F76B1C) !important;
  }
}

.card-body-profile {
  padding: 30px;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  background: #0003C7 !important;
}

.card-body-welcome {
  padding: 0px 30px;
  height: 100%;
  border-radius: 15px;
  box-shadow: 0 10px 30px 1px rgba(0, 0, 0, 0.06);
  background: #FEEFE8 !important;
}

.bold-text_commision {
  font-size: 14px;
  color: #fff !important;
}

.border-detail {
  border-color: none;
}

.banner__welcome {
  justify-content: space-between;
  align-items: center;
  padding: 12px 54px 0px 54px;

  @media screen and (min-width: 900px) {
    display: flex;
  }
}

.banner__text {
  text-align: center;
}

.banner__title {
  color: #2962FF !important;
  font-weight: 500 !important;

  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
}

.banner__title-download {
  color: #333333 !important;
  font-weight: 500 !important;
  padding: 10px;
  // border-radius: 10px;
  border-bottom: 4px solid #4b00e05e !important;
  // border-top: 4px solid #4a00e0 !important;

  @media screen and (max-width: 900px) {
    font-size: 40px;
  }
}

.banner_close {
  position: absolute;
  top: 16px;
  right: 19px;
}

.text__detail {
  font-size: 14px;
  font-weight: 400;
  color: #252525 !important;
}

.text__detail_you {
  margin: 0px !important;
  font-size: 14px;
  font-weight: 400;
  color: #252525 !important;
}

.card-history {
  width: 100%;
  margin: 4px 0px;
  min-height: 60px;
  padding: 10px 14px;
  border-radius: 12px;
  border-style: solid;

  // background: #1C3FFD;
  @include themify($themes) {
    border-color: themed("colorText");
  }
}

.card-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card__title {
  display: flex;
  align-items: center;
  margin-bottom: 0px !important;
}

.icon_corder {
  border-radius: 50%;
  margin-right: 12px;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 32px;
  border-style: solid !important;
  width: 32px;

  @include themify($themes) {
    background: themed("colorText");
    color: themed("colorText");
  }
}

.color_svg {
  @include themify($themes) {
    color: themed("colorBackgroundBody");
  }
}

.text__card_title {
  font-weight: 500;
  font-size: 14px !important;

  @include themify($themes) {
    color: themed("colorTextProfile");
  }
}

.text-deskripstion {
  color: #fff !important;
  font-size: 14px;
  margin: 4px 42px !important;
}

.text-dolar {
  color: #fff !important;
  // margin-right: 8px;
  margin: 0px 42px;
}

.icon_download {
  @include themify($themes) {
    // color: themed("colorTextProfile");
  }
}

.border_radius {
  border-radius: 15px !important;
}

.card-historys {
  height: 320px !important;
  overflow-y: auto;
}