.profile {}

.profile__card {
  height: 100%;
  padding: 0;

  @include themify($themes) {
    background-color: themed('colorBackground');
  }

  .nav-item {
    text-transform: uppercase;
    font-weight: 500;
  }

  .tab-content {
    padding: 0 10px 10px 10px;
  }

  .timeline__content {

    @include themify($themes) {
      background: themed('colorBackgroundBody');
    }

    &:after {

      @include themify($themes) {
        border-left-color: themed('colorBackgroundBody');
      }
    }
  }

  .timeline .timeline__item:nth-child(even) {

    .timeline__content {

      &:after {

        @include themify($themes) {
          border-right-color: themed('colorBackgroundBody');
        }
      }
    }
  }

  &--calendar {

    .rbc-toolbar {
      -webkit-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
  }
}

.panel__body {
  padding: 30 !important;
}

.profile__information {
  padding: 30px 40px;
  display: flex;

  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 10px;
  }

  @include directify($directions) {
    text-align: directed('left');
  }

  @media (max-width: 1345px) and (min-width: 1200px) {
    padding: 30px 15px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 360px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    display: block;
  }

  .profile__data {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 360px) {
      width: 100%;
      text-align: center;
      padding: 0;
    }
  }
}

.profile__avatar {
  height: 140px;
  width: 140px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-radius: 10%;

  @media screen and (min-width: 768px) {
    display: block;
    justify-content: start;
  }

  img {
    height: 100%;
  }

  svg {
    display: block;
  }

  @include themify($themes) {
    color: themed('colorEmoji');
  }


  @media (max-width: 1345px) and (min-width: 1200px) {
    height: 130px;
    width: 130px;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    height: 100%;
    width: 100%;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    .avatarProfile {
      display: block;
      align-items: center;
      justify-content: center;
      text-align: center;
    }

  }
}






.profile__stats {
  display: flex;
  justify-content: space-around;
}

.profile__stat {
  text-align: center;
  padding-top: 5px;
  padding-bottom: 15px;
}

.profile__stat-number {
  color: $color-blue;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin: 0;
}

.profile__stat-title {
  margin: 0;
  color: $color-additional;
  font-size: 12px;
  line-height: 14px;
}

.profile__data {
  // width: calc(100% - 140px);
  padding-left: 25px;

  @media screen and (max-width: 768px) {
    margin-top: 12px;
    padding-left: 0px;
  }

  // @include directify($directions) {
  //   #{directed('padding-left')}: 25px;
  // }

  @media (min-width: 320px) and (max-width: 480px) {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.profile__name {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1.3rem;
  margin: 0;
  line-height: 18px;

  &hover {
    color: $color-title-profile;
  }

  @include themify($themes) {
    color: themed('colorTextProfile');
  }

  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;


  }
}

.profile__flex__status {
  display: flex;

  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;


  }
}

.profile__status {
  font-weight: 500;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-top: 12px;
  line-height: 18px;
  opacity: 0.6;

  @include themify($themes) {
    color: themed('colorTextProfile');
  }

  @media (min-width: 320px) and (max-width: 480px) {
    text-align: center;
    margin-right: 0 !important;


  }
}

.profile__contact {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 18px;
}

.profile__btn {
  margin-top: 10px;
  margin-bottom: 0;
  padding: 8px 15px;
}

.profile__activity {
  display: flex;
  border-bottom: solid 1px #e7e7e7;
  padding: 20px 0;

  &:last-child {
    border: none;
  }

  img {
    display: inline-block;
    vertical-align: top;
    margin-top: 10px;
    width: auto;

    @include directify($directions) {
      #{directed('margin-right')}: 20px;
    }

    &:last-child {

      @include directify($directions) {
        #{directed('margin-right')}: 0px;
      }
    }

    @media screen and (max-width: 576px) {
      width: 100%;
    }
  }
}

.profile__activity-avatar {
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 64px;

  @include directify($directions) {
    #{directed('margin-right')}: 20px;
  }

  img {
    height: 64px;
    width: 64px;
    margin: 0;
  }
}

.profile__activity-name {
  font-weight: 700;
  color: $color-blue;
}

.profile__activity-date {
  font-weight: 400;
  color: $color-additional;
  font-size: 10px;
}

.profile__current-tasks-title {
  padding: 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  margin: 0;

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
    color: themed('colorText');
  }

  span {
    color: $color-additional;
    font-size: 13px;
  }
}

.profile__current-tasks {
  position: relative;
  padding-bottom: 40px;
}

.profile__current-task {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin: 0 20px;
  padding: 10px 0 10px 0;

  @include directify($directions) {
    #{directed('padding-right')}: 0;
    #{directed('padding-left')}: 0;
    text-align: #{directed('left')};
  }

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
    color: themed('colorText');
  }

  &:last-of-type {
    border-bottom: none;
  }

  &--disabled {
    cursor: default;
    opacity: 0.7;
  }

  label {
    margin-right: 35px;
  }

  .badge {
    text-transform: uppercase;
    font-size: 9px;
    padding: 6px 10px;
    display: inline-table;
    height: 20px;
    color: $color-white;

    @include directify($directions) {
      #{directed('margin-left')}: auto;
      #{directed('margin-right')}: 5px;
    }

    &.badge-info {
      background-color: $color-accent;
    }

    &.badge-error {
      background-color: $color-red;
    }
  }
}

.profile__current-task-checkbox {

  &--disabled {
    cursor: default;
  }

  @include directify($directions) {
    #{directed('left')}: 0px;
  }
}

.profile__see-all-tasks {
  position: absolute;
  bottom: 20px;

  @include directify($directions) {
    #{directed('right')}: 20px;
  }
}

.profile__project {
  flex-wrap: wrap;
  display: flex;

  @media screen and (max-width: 768px) {
    width: 120px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.profile__border__projects {
  border-style: dashed !important;
  border-color: #e4e6ef !important;
  min-width: 125px !important;
  border-radius: .45rem !important;
  margin-right: 1.5rem !important;
  padding: 0.75rem 1rem !important;
  // margin-bottom: 1.5rem !important;
  border: 1px solid #eff2f5;

  @media screen and (max-width: 768px) {
    min-width: 100%;
    margin-right: 0.7rem !important;
  }
}

.profile__flex_border_icon {
  align-items: center !important;
  display: flex;

  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
  }


}

.profile__text_border {
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 8px;

  @media (min-width: 320px) and (max-width: 480px) {
    margin-left: 0;
    font-weight: 0.5rem;
    text-align: center;
    justify-content: center;
    justify-items: center;

  }

  @include themify($themes) {
    color: themed('colorEmoji');
  }
}

.profile__text__border_down {
  font-size: 1rem;
  font-weight: 500;
  // margin-top: 8px;

  @include themify($themes) {
    color: themed('colorFitness');
  }
}

.profile__border__icon__up {
  font-size: 18px;

  @include themify($themes) {
    color: themed('colorIconUp');
  }
}

.profile__overview {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.2);
}

.profile__overview__margin {
  margin-bottom: 1.75rem !important;
}

.profile__overview_text {
  font-weight: 500 !important;
  flex: 0 0 auto;
  width: 33.33333333%;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.profile__overview_name {
  font-size: 14px;
  font-weight: 400;
  flex: 0 0 auto;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.profile__settings__form {
  width: 66.555%;
  flex: 0 0 auto;
  text-align: end;

  @include themify($themes) {
    color: themed('colorText');
  }
}

.change__email__btn {
  width: 40%;
  margin-right: 0;
}

.flat_women {
  // width: 94px;
  display: flex;
  // justify-content: right;
  // align-items: right;
  height: 200px;
  margin: 0 auto;
}

.flat_toc {
  // width: 94px;
  height: 200px;
}

.flat_line {
  // width: 94px;
  height: 50px;
}

.text-payment {
  font-size: 16px;
  font-weight: 400;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}