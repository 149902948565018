.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) * -.5);
    margin-left: calc(var(--bs-gutter-x) * -.5);
}

.add-payment_border_width {
    flex: 0 0 auto;
    width: 40%;
}

.card-payment {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 64px;
    min-width: 0;
    word-wrap: break-word;
    // background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
}

.card-payment__loading {
    position: absolute;
    background-color: #fff;
    opacity: 0.3;
    right: 10px;
    left: 180px;
}

.card-payment__loading-delete {
    // position: absolute;
    // background-color: #fff;
    // opacity: 0.3;
    color: #ffffff;
    z-index: 10000;
    // top: 100px;
    font-size: 18px;
    // right: 80px;
    // left: 0px;
}

.card-payment__loading-update {
    // position: absolute;
    // background-color: #fff;
    // opacity: 0.3;
    color: #ffffff;
    z-index: 10000;
    // top: 100px;
    font-size: 8px;
    // right: 80px;
    // left: 0px;
}

.border_card {
    border: 1px solid #dee2e6 !important;
    flex-direction: row !important;
    align-items: center !important;
    border-radius: 0.75rem;
}

.border-bank {
    background-color: rgb(202, 10, 10);
}

.card_method {
    // background-color: transparent;
    font-family: "Open Sans";
    padding: 1.5rem;
    box-shadow: none;
    // flex: 1 1 auto;
    justify-content: space-between;
    padding: 1rem 1rem;
    display: flex !important;
}

.card-mastercard {
    width: 24px !important;
    height: 24px !important;
    // margin-right: 1rem !important;
    margin-bottom: 0rem !important;
    cursor: pointer;
    // color: rgb(66, 66, 66);
    color: white;
}

.card-payPal {
    width: 28px !important;
    height: 28px !important;
    // margin-right: 1rem !important;
    margin-bottom: 0rem !important;
    color: rgb(11, 65, 214);
}

.card-payPal-modal {
    font-size: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: rgb(11, 65, 214);
}

.card-Bank-modal {
    font-size: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: rgb(11, 65, 214);
}

.card-warning-modal {
    font-size: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: rgb(214, 11, 21);
}

.card-success-modal {
    font-size: 40px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: rgb(1, 170, 23);
}

.pencil__icon {
    color: #ffffff !important;
    margin-left: 6px;
    // margin-left: auto !important;
    cursor: pointer;
}

.pencil__delete {
    color: #344767 !important;
    margin-left: 12px !important;
    cursor: pointer;
}

.card__title {
    margin-bottom: 40px;
    text-transform: lowercase;
    font-size: 16px;
    // margin-left: 100px;
    font-weight: 300;
    position: relative;

    @include themify($themes) {
        color: themed('colorTextProfile');
    }
}