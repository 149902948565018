.badge-status {
    width: 100px;
    height: auto;
    padding: 0.50rem 0.40rem;
}

.border__font-awesome {
    width: 28px;
    height: 28px;
    padding: 4px;
    background-color: $color-border-icon-exchange;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;

    .color__font-awesome{
        color: $color-icon-exchange;
        font-size: 16px;
    }
}