.invoice {
  padding: 35px;
  @include directify($directions) {
    text-align: directed('left');
  }

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.invoice__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  flex-wrap: wrap;

  p {
    color: $color-additional;
    margin: 0;
  }
}

.invoice__logo {
  width: 120px;
  height: 16px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: left;
  background-size: contain;

  @include themify($themes) {
    background-image: themed('logoImg');
  }
}

.invoice__head-right {
  width: 100%;
  margin-top: 30px;

  .invoice__date {
    margin-bottom: 10px;
  }
}

.invoice__total {
  @include directify($directions) {
    text-align: directed('right');
  }
  margin-top: 15px;
}

.invoice__grand-total {
  font-weight: 500;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 30px;
}

.invoice__toolbar {
  justify-content: flex-end;

  button {
    @include directify($directions) {
      #{directed('margin-left')}: 15px;
    }
    @include directify($directions) {
      #{directed('margin-right')}: 0px;
    }
  }
}

@media screen and (min-width: 768px) {

  .invoice {
    padding: 50px;
  }

  .invoice__head-right {
    width: auto;
    @include directify($directions) {
      text-align: directed('right');
    }
    margin-top: 0;
  }
}

.invoice__list {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  margin-top: 12px;
  border-radius: .5rem;
}

.list-group-item {
  position: relative;
  display: block;
  padding: .5rem 1rem;
  color: inherit;
  // background-color: #fff;
  border: 1px solid rgba(0,0,0,.125);
}

.list-group-invoice {
  display: flex!important;
  border: 0!important;
  border-width: 0!important;
  justify-content: space-between!important;
  margin-bottom: .5rem!important;
  padding-left: 0!important;
  border-radius: .75rem;
}

.list__group-column {
  flex-direction: column!important;
  display: flex!important;
}

.list__group-date {
  font-weight: 600!important;
  font-size: .875rem!important;
  line-height: 1.5;

  @include themify($themes) {
    background-image: themed('colorTextProfile');
  }
}

.list__group-invoice {
  font-size: .75rem!important;
  line-height: 1.25;
}

// .list__group-invoice {
//   font-size: .875rem!important;
//   display: flex!important;
//   align-items: center!important;
//   line-height: 1.5;
// }