.btn {
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  // margin-right: 15px;
  transition: all 0.4s;
  font-size: 14px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &:last-child {
    margin-right: 0;
  }

  &:before {
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 50%;
    background-color: $color-accent;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    transform: translate(-50%, -50%);
    z-index: -1;
    content: "";
    top: 0;
    left: 0;
  }

  p {
    display: flex;
    transition: all 0.3s;
    font-weight: 500;
    color: #444444;
  }

  svg {
    height: 20px;
    width: 20px;
    margin: 2px 5px 0 0;
    transition: all 0.3s;
    fill: #444444;
  }

  &:hover, &:focus, &:active, &:active:focus {
    outline: none;
    box-shadow: none !important;

    &:before {
      height: 500%;
      width: 225%;
    }
  }

  &:focus, &:active, &:active:focus {

    &:before {
      transition: all 0s;
    }
  }

  &.square {
    border-radius: 0;
  }

  &.rounded {
    border-radius: 30px !important;
  }

  &.icon {
    // padding: 10px 15px;

    &:hover {

      p {
        color: #646777;
      }

      svg {
        fill: #646777;
      }
    }

    &.icon--right {

      svg {
        margin: 2px 0 0 5px;
      }
    }
  }

  &.btn-sm {
    padding: 5px 25px;
    font-size: 14px;
  }

  &.btn-lg {
    padding: 12px 25px;
    font-size: 14px;
  }

  &.btn-secondary {

    @include themify($themes) {
      background-color: transparent;
      color: transparent;
      border-color: transparent;
    }

    &:before {
      background-color: transparent;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: transparent;
      color: transparent;
    }

    &:not([disabled]):not(.disabled):active, &:not([disabled]):not(.disabled).active {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .btn-copy {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 5px;
    text-align: center;

    &:before {
      background-color: #d8dfe9;
    }

    // &:hover, &:focus, &:active, &:active:focus {
    //   border-color: transparent;
    //   color: transparent;
    // }

    &:not([disabled]):not(.disabled):active, &:not([disabled]):not(.disabled).active {
      background-color: transparent;
      border-color: transparent;
    }
  }


  &.btn-outline-secondary {
    border-color: #e7e2e2;

    &, p {

      @include themify($themes) {
        color: themed('colorText');
      }
    }

    svg {
      @include themify($themes) {
        fill: themed('colorText');
      }
    }

    &:before {
      background-color: #e7e2e2;
    }

    &:hover, &:focus, &:active, &:active:focus {
      background: transparent;
      border-color: #e7e2e2;
      color: #444444;

      p {
        color: #444444;
      }

      svg {
        fill: #444444;
      }
    }
  }

  &.btn-primary {
    background-color: $color-blue;
    border-color: $color-blue;

    &:before {
      background-color: $color-blue-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-blue-hover;
    }
  }

  &.btn-account {
    background-color: $color-blue-account;
    border-color: $color-blue-account;

    &:before {
      background-color: $color-blue-account-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-blue-account-hover;
    }
  }

  &.btn-readmore {
    background-color: $color-blue-account;
    border-color: $color-blue-account;

    &:before {
      background-color: $color-blue-account-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-blue-account-hover;
    }
  }

  &.btn-cancel {
    background-color: $color-text;
    border-color: $color-text;

    &:before {
      background-color: $color-gray-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-gray-hover;
    }
  }

  &.btn-delete {
    background-color: $color-red;
    border-color: $color-red;

    &:before {
      background-color: $color-red-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-red-hover;
    }
  }


  &.btn-profile-email {
    background-color: $color-light-gray;
    border-color: $color-light-gray;

    &:before {
      background-color: $color-light-gray-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-light-gray-hover;
    }
  }

  &.btn-profile-cancel {
    margin-bottom: 0px;
    margin-left: 24px;
    background-color: $color-light-gray;
    border-color: $color-light-gray;

    &:before {
      background-color: $color-light-gray-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-light-gray-hover;
    }
  }

  &.btn-outline-primary {
    color: $color-blue;
    border-color: $color-blue;

    p {
      color: $color-blue;
    }

    svg {
      fill: $color-blue;
    }

    &:before {
      background-color: $color-blue;
    }
  }

  &.btn-success {
    background-color: $color-accent;
    border-color: $color-accent;

    &:before {
      background-color: $color-accent-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-accent-hover;
    }
  }

  &.btn-outline-success {
    color: $color-accent;
    border-color: $color-accent;

    p {
      color: $color-accent;
    }

    svg {
      fill: $color-accent;
    }

    &:before {
      background-color: $color-accent;
    }
  }

  &.btn-warning {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: #ffffff;

    &:before {
      background-color: $color-yellow-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-yellow-hover;
    }
  }

  &.btn-outline-warning {
    color: $color-yellow;
    border-color: $color-yellow;

    p {
      color: $color-yellow;
    }

    svg {
      fill: $color-yellow;
    }

    &:before {
      background-color: $color-yellow;
    }
  }

  &.btn-danger {
    background-color: $color-red;
    border-color: $color-red;
    color: #ffffff;

    &:before {
      background-color: $color-red-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-red-hover;
    }
  }

  &.btn-payment {
    background: $color-gradient-btn-payment;
    border-color: $color-gradient-btn-payment;
    color: #ffffff;

    &:before {
      background: $color-gradient-btn-payment-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-gradient-btn-payment-hover;
    }
  }

  &.btn-banner {
    background: $color-gradient-btn-payment;
    border-color: $color-gradient-btn-payment;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    width: 149px;
    color: #ffffff;

    &:before {
      background: $color-gradient-btn-payment-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-gradient-btn-payment-hover;
    }
  }

  &.btn-payment-popup {
    background: $color-btn-payment-popup;
    border-color: $color-btn-payment-popup;
    color: #ffffff;

    &:before {
      background: $color-btn-payment-popup-hover;
    }

    &:hover, &:focus, &:active, &:active:focus {
      border-color: $color-btn-payment-popup-hover;
    }
  }

  &.btn-outline-danger {
    color: $color-red;
    border-color: $color-red;

    p {
      color: $color-red;
    }

    svg {
      fill: $color-red;
    }

    &:before {
      background-color: $color-red;
    }
  }

  &, &.btn-primary, &.btn-danger, &.btn-warning, &.btn-account, &.btn-success, &.btn-outline-secondary, &.btn-secondary, &.btn-readmore,
  &.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success,
  &.icon, &.icon.btn-secondary, &.btn-payment, &.btn-payment-popup, &.btn-delete, &.btn-cancel, &.btn-banner {

    &.disabled {
      background-color: #f2f4f7;
      border-color: #f2f4f7;
      color: #dddddd;
      pointer-events: none;

      p {
        color: #dddddd;
      }

      svg {
        fill: #dddddd;
      }

    }
  }

  &.btn-primary, &.btn-danger, &.btn-warning, &.btn-success, &.btn-account, &.btn-banner, &.btn-readmore, &.btn-payment, &.btn-payment-popup, &.btn-delete, &.btn-cancel{
    p {
      color: #ffffff;
    }

    svg {
      fill: #ffffff;
    }
  }

  &.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success, &.btn-delete, &.btn-cancel {

    &:hover, &:focus, &:active, &:active:focus {
      color: #ffffff;
      background: transparent;
    }
  }

  &.btn-primary, &.btn-danger, &.btn-account, &.btn-warning, &.btn-success, &.btn-readmore, &.btn-banner
  &.btn-outline-primary, &.btn-outline-danger, &.btn-outline-warning, &.btn-outline-success, &.btn-payment, &.btn-payment-popup, &.btn-delete, &.btn-cancel {

    &:hover, &:focus,
    &:active, &:active:focus {

      p {
        color: #ffffff;
      }

      svg {
        fill: #ffffff;
      }
    }

  }

  &.expand {

    svg {
      width: 0;
      transition: all 0.3s;
    }

    &.expand--load {

      @keyframes rotating {
        from {
          transform: rotate(0deg);
        }
        to {
          transform: rotate(360deg);
        }
      }
      svg {
        width: 14px;
        animation: rotating 2s linear infinite;
      }
    }
  }
}

.btn-toolbar {
  margin-top: 15px;
  margin-bottom: 10px;

  & > * {
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: -10px;
  }

  &.btn-toolbar--center {

    & > * {
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.btn-group {
  border-radius: 5px;
  margin-bottom: -10px;

  .btn {
    margin-right: 0;
    padding: 10px 15px;
    font-weight: 500;
  }

  &.btn-group--justified {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .btn {
      width: 100%;
    }
  }

  &.btn-group--icons {

    .btn {
      padding: 7px 8px;
      line-height: 14px;
    }
  }

  &.open .dropdown-toggle {
    box-shadow: none;
  }
}

button:focus, button:active {
  outline: none;
}

.open > .dropdown-toggle.btn-default,
.btn-default:active:focus, .btn-default:active:focus,
.btn-default.focus, .btn-default:focus {

  @include themify($themes) {
    background-color: themed('colorBackground');
  }
}

.btn__filter-list {
  justify-content: flex-end;
  display: flex;
  height: 100%;
  margin-bottom: 10px;

  .filter-status {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;
  }
}

@import url('//fonts.googleapis.com/css?family=Roboto:500');

@import url('//maxcdn.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.min.css');

.face-button {
  $height: 54px;
  $accentColor: rgb(30, 1, 109);

  height: $height;
  display: inline-block;
  border: 3px solid $accentColor;
  border-radius: 12px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  color: $accentColor;
  overflow: hidden;

  .icon {
    margin-right: 6px;
  }

  .face-primary,
  .face-secondary {
    display: block;
    padding: 0 32px;
    line-height: $height;
    transition: margin .4s;
  }

  .face-primary {
    background-color: $accentColor;
    color: #fff;
  }

  &:hover .face-primary {
    margin-top: -$height;
  }
}
